<template>
    <v-container v-if="isBusy">
        <video-card v-for="i in 5" :key="i" loading class="mb-2" />
    </v-container>
    <v-container v-else-if="videosExist" class="px-0">
        <video-card
            v-for="(video, index) in videos"
            :key="'video-card-' + video.id + '-' + index"
            :video="video"
            class="mb-2"
        />
    </v-container>
    <v-container v-else class="px-0">
        <v-row class="mx-0">
            <v-col cols="12" class="d-flex justify-center pt-8 px-0">
                <span>No videos found to approve</span>
            </v-col>
        </v-row>
    </v-container>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import VideoCard from './VideoCard.vue';

import type { VideoPr } from '@/types/VideoPr';

const VideosListProps = Vue.extend({
    name: 'VideosList',
    props: {
        videos: {
            type: Array as PropType<Partial<VideoPr>[]>,
            default(): VideoPr[] {
                return [];
            }
        },
        loading: {
            type: Boolean,
            default() {
                return true;
            }
        },
        updating: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component({
    components: {
        VideoCard
    }
})
export default class VideosList extends VideosListProps {
    get isBusy() {
        return this.loading || this.updating;
    }

    get videosExist() {
        return Boolean(this.videos.length);
    }
}
</script>
