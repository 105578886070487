import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c(VCard,{attrs:{"elevation":hover ? 2 : 0}},[_c(VListItem,{attrs:{"three-line":""}},[_c(VListItemAvatar,{staticClass:"white video-container",class:{
                    'can-play': _vm.hasPreview && !_vm.loading
                },attrs:{"rounded":"","size":"160"},on:{"click":_vm.preview}},[(!_vm.hasPreview || _vm.loading)?_c(VIcon,{attrs:{"large":"","color":"grey lighten-2"}},[_vm._v(" video ")]):_c('video',{attrs:{"width":"100%","loop":"","muted":"","src":_vm.previewSrc},domProps:{"muted":true}},[_vm._v(" Your browser does not support the video element. Please update your browser version. ")])],1),(_vm.loading)?_c(VListItemContent,[_c(VSkeletonLoader,{attrs:{"type":"article"}})],1):_c(VListItemContent,[_c(VListItemTitle,{staticClass:"text-4 mb-1 font-weight-bold"},[_c('router-link',{staticClass:"visitable",class:{ visited: _vm.isVisited },attrs:{"to":_vm.link}},[_vm._v(" "+_vm._s(_vm.video.title)+" ")])],1),_c(VListItemSubtitle,{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(_vm.published)+" ")]),_c(VListItemSubtitle,{domProps:{"innerHTML":_vm._s(_vm.video.description)}}),_c(VListItemSubtitle,_vm._l((_vm.tags),function(tag){return _c(VChip,{key:tag,staticClass:"mr-1 mb-1",attrs:{"small":""}},[_vm._v(" "+_vm._s(tag)+" ")])}),1)],1),_c(VListItemAction,[_c('video-actions',{attrs:{"video":_vm.video},on:{"action":_vm.onVideoAction}})],1)],1),_c('video-preview',{attrs:{"video":_vm.video}})],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }