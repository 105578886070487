import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VMenu,{attrs:{"transition":"slide-y-transition","close-on-click":true,"close-on-content-click":true,"offset-y":true,"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"fab":"","plain":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("ellipsis-v")])],1)]}}])},[_c(VList,[_c(VListItem,{key:"edit",on:{"click":function($event){return _vm.onAction('approve')}}},[_c(VListItemIcon,{staticClass:"mr-2"},[_c(VIcon,{attrs:{"small":""}},[_vm._v("check")])],1),_c(VListItemTitle,[_vm._v("Approve")])],1),_c(VListItem,{key:"delete",on:{"click":function($event){return _vm.onAction('reject')}}},[_c(VListItemIcon,{staticClass:"mr-2"},[_c(VIcon,{attrs:{"small":"","color":"error"}},[_vm._v("xmark")])],1),_c(VListItemTitle,{staticClass:"error--text"},[_vm._v(" Reject ")])],1),_c(VListItem,{key:"download",on:{"click":function($event){return _vm.onAction('download')}}},[_c(VListItemIcon,{staticClass:"mr-2"},[_c(VIcon,{attrs:{"small":""}},[_vm._v("download")])],1),_c(VListItemTitle,[_vm._v("Download")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }