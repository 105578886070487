<template>
    <v-hover v-slot="{ hover }">
        <v-card :elevation="hover ? 2 : 0">
            <v-list-item three-line>
                <v-list-item-avatar
                    rounded
                    size="160"
                    class="white video-container"
                    :class="{
                        'can-play': hasPreview && !loading
                    }"
                    @click="preview"
                >
                    <v-icon
                        v-if="!hasPreview || loading"
                        large
                        color="grey lighten-2"
                    >
                        video
                    </v-icon>
                    <video v-else width="100%" loop muted :src="previewSrc">
                        Your browser does not support the video element. Please
                        update your browser version.
                    </video>
                </v-list-item-avatar>
                <v-list-item-content v-if="loading">
                    <v-skeleton-loader type="article" />
                </v-list-item-content>
                <v-list-item-content v-else>
                    <v-list-item-title class="text-4 mb-1 font-weight-bold">
                        <router-link
                            :to="link"
                            class="visitable"
                            :class="{ visited: isVisited }"
                        >
                            {{ video.title }}
                        </router-link>
                    </v-list-item-title>
                    <v-list-item-subtitle class="font-weight-medium">
                        {{ published }}
                    </v-list-item-subtitle>
                    <!-- eslint-disable-next-line vue/no-v-text-v-html-on-component, vue/no-v-html -->
                    <v-list-item-subtitle v-html="video.description" />

                    <v-list-item-subtitle>
                        <v-chip
                            v-for="tag in tags"
                            :key="tag"
                            small
                            class="mr-1 mb-1"
                        >
                            {{ tag }}
                        </v-chip>
                    </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                    <video-actions :video="video" @action="onVideoAction" />
                </v-list-item-action>
            </v-list-item>

            <video-preview :video="video" />
        </v-card>
    </v-hover>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import { formatDateTime } from '@/utils/helpers';
import { InjectReactive } from '@/utils/decorators';

import VideoActions from './VideoActions.vue';

import VideoPreview from './VideoPreview.vue';

import type { AdminVideoAction, VideoPr } from '@/types/VideoPr';

const VideoCardOptions = Vue.extend({
    name: 'VideoCard',
    props: {
        video: {
            type: Object as PropType<Partial<VideoPr>>,
            default() {
                return {
                    id: 0,
                    title: '',
                    description: '',
                    tags_array: [],
                    publish_time: null,
                    announcement: null,
                    announcement_id: 0,
                    preview_available: false
                };
            }
        },
        loading: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component({
    components: {
        VideoActions,
        VideoPreview
    }
})
export default class VideoCard extends VideoCardOptions {
    isVisited = false;

    get published() {
        const stamp =
            this.video.publish_time ||
            this.video.announcement?.min_distribution_time;

        return stamp ? formatDateTime(stamp) : '';
    }

    get link() {
        return `/announcements/review/${this.video.announcement_id}/video`;
    }

    get tags() {
        return [...new Set(this.video.tags_array)];
    }

    get hasPreview() {
        return this.video.preview_available;
    }

    get previewSrc() {
        return `/video_prs/generate_preview/${this.video.id}?get_preview=1&_=${this.cacheBustingTag}`;
    }

    get cacheBustingTag() {
        return +new Date();
    }

    @InjectReactive({
        from: 'onAction',
        default() {
            return () => void 0;
        }
    })
    onAction!: (action: AdminVideoAction, video: Partial<VideoPr>) => void;

    onVideoAction(action: AdminVideoAction) {
        return this.onAction(action, this.video);
    }

    preview() {
        if (this.hasPreview) {
            this.$store.dispatch(
                'modal/open',
                `video-preview-${this.video.id}`
            );

            this.isVisited = true;
        }
    }
}
</script>

<style lang="scss" scoped>
a.visited {
    color: purple;
}

.video-container.can-play {
    cursor: pointer;

    &::after {
        content: '▶';
        position: absolute;
        opacity: 0.5;
        color: white;
        text-shadow: 0 3px black;
        z-index: 100;
        font-size: 2em;
        top: calc(50% - 0.75em);
        left: calc(50% - 0.75em);
        width: 1.5em;
        height: 1.5em;
        transform: scale(1);
        pointer-events: none;
        transition: all 0.2s ease-in-out;
    }

    &:hover:after {
        opacity: 0.8;
        transform: scale(1.1);
        text-shadow: 0 3px #666;
    }
}
</style>
