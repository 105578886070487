<template>
    <modal :id="id" title="Approve Video">
        <template #default>
            <p>
                This will
                <strong>approve</strong>
                the video
                <strong>{{ video.title }}</strong>
                .
            </p>
            <v-checkbox v-model="includeUbc" label="Include UBC" />
        </template>
        <template #actions>
            <v-btn text :block="$vuetify.breakpoint.smAndDown" @click="close">
                Cancel
            </v-btn>
            <v-spacer />
            <v-btn
                color="primary"
                :block="$vuetify.breakpoint.smAndDown"
                @click="approve"
            >
                Confirm
            </v-btn>
        </template>
    </modal>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { InjectReactive } from '@/utils/decorators';

import { Modal } from '@/components/Modal';
import APrioritySelectInput from '@/components/AForm/Inputs/APrioritySelectInput';

import type { VideoPr } from '@/types/VideoPr';
import { VideoStatus } from '@/types/Video';

@Component({
    components: {
        Modal,
        APrioritySelectInput
    }
})
export default class VideoApprove extends Vue {
    id = 'video-approve';

    includeUbc = true;

    @InjectReactive({
        from: 'video',
        default() {
            return {
                id: 0
            };
        }
    })
    video!: VideoPr;

    approve() {
        this.$emit('confirm', {
            action: 'approve',
            data: {
                status: VideoStatus.Approved,
                include_ubc: this.includeUbc
            }
        });

        this.close();
    }

    close() {
        this.$store.dispatch('modal/close', this.id);
    }
}
</script>
