<template>
    <modal :id="id" :title="video.title" :persistent="false" max-width="1000px">
        <template #default>
            <video width="100%" controls loop playsinline :src="src">
                Your browser does not support the video element. Please update
                your browser version.
            </video>
        </template>
        <template #actions>
            <v-btn text :block="$vuetify.breakpoint.smAndDown" @click="close">
                Cancel
            </v-btn>
            <v-spacer />
            <v-btn
                class="error--text"
                :block="$vuetify.breakpoint.smAndDown"
                @click="onVideoAction('reject')"
            >
                Reject
            </v-btn>
            <v-btn
                color="primary"
                :block="$vuetify.breakpoint.smAndDown"
                @click="onVideoAction('approve')"
            >
                Approve
            </v-btn>
        </template>
    </modal>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import { InjectReactive } from '@/utils/decorators';
import { Modal } from '@/components/Modal';

import type { AdminVideoAction, VideoPr } from '@/types/VideoPr';

const VideoPreviewProps = Vue.extend({
    name: 'VideoPreview',
    props: {
        video: {
            type: Object as PropType<Partial<VideoPr>>,
            default() {
                return {
                    id: 0,
                    title: ''
                };
            }
        }
    }
});

@Component({
    components: {
        Modal
    }
})
export default class VideoPreview extends VideoPreviewProps {
    id = `video-preview-${this.video.id}`;

    get src() {
        return `/video_prs/preview/${this.video.id}?time=${this.cacheBustingTag}`;
    }

    get cacheBustingTag() {
        return +new Date();
    }

    @InjectReactive({
        from: 'onAction',
        default() {
            return () => void 0;
        }
    })
    onAction!: (action: AdminVideoAction, video: Partial<VideoPr>) => void;

    close() {
        this.$store.dispatch('modal/close', this.id);
    }

    onVideoAction(action: AdminVideoAction) {
        this.close();

        return this.onAction(action, this.video);
    }
}
</script>
