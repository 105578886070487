<template>
    <validation-provider
        ref="provider"
        v-slot="{ errors, invalid, validated }"
        slim
        :rules="rules"
        :name="fieldName"
        :vid="$attrs.vid"
    >
        <v-select
            :error-messages="errors"
            :error="validated && invalid"
            :required="required"
            :disabled="disabled"
            :items="options"
            :label="label"
            :menu-props="{ offsetY: true, nudgeLeft: 15 }"
            hide-details
            v-bind="$attrs"
            v-on="$listeners"
        >
            <template #selection="{ item }">
                <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                        <v-icon
                            v-if="item.priority_level"
                            :color="item.color"
                            small
                            v-bind="attrs"
                            v-on="on"
                        >
                            flag
                        </v-icon>
                    </template>
                    {{ item.name }}
                </v-tooltip>
            </template>
            <template #item="{ item }">
                <v-icon
                    v-if="item.priority_level"
                    :color="item.color"
                    small
                    left
                >
                    flag
                </v-icon>
                {{ item.name }}
            </template>
        </v-select>
    </validation-provider>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import { ValidationProvider } from 'vee-validate';

import type { PriorityLevel } from '@/types/PriorityLevel';

const APrioritySelectInputProps = Vue.extend({
    name: 'APrioritySelectInput',
    props: {
        label: {
            type: String,
            default() {
                return '';
            }
        },
        field: {
            type: String,
            default() {
                return '';
            }
        },
        name: {
            type: String,
            default() {
                return '';
            }
        },
        rules: {
            type: [String, Object],
            default() {
                return '';
            }
        },
        disabled: {
            type: Boolean,
            default() {
                return false;
            }
        },
        options: {
            type: Array as PropType<PriorityLevel[]>,
            default() {
                return [
                    { name: 'High', color: 'red', priority_level: 1 },
                    { name: 'Medium', color: 'orange', priority_level: 2 },
                    { name: 'Low', color: 'yellow', priority_level: 3 },
                    { name: 'None', color: 'grey', priority_level: 4 }
                ];
            }
        }
    }
});

@Component({
    inheritAttrs: false,
    components: {
        ValidationProvider
    }
})
export default class APrioritySelectInput extends APrioritySelectInputProps {
    $refs!: {
        provider: InstanceType<typeof ValidationProvider>;
    };

    get fieldName() {
        return this.field || this.label || this.name;
    }

    get required() {
        return typeof this.rules === 'string'
            ? this.rules?.includes('required')
            : Object.keys(this.rules).includes('required');
    }
}
</script>

<style lang="scss" scoped>
.v-select::v-deep {
    max-width: min-content;
    padding-top: 0;
    margin-top: 0;

    > .v-input__control {
        > .v-input__slot {
            &:before {
                display: none;
            }

            &:after {
                display: none;
            }
        }
    }

    .v-input__append-inner svg {
        height: 16px;
        width: 16px;
    }
}
</style>
