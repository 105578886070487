<template>
    <v-container>
        <v-row class="my-2 px-3">
            <div class="text-h6 text-md-h5 font-weight-bold">
                Video Approval
            </div>
        </v-row>

        <videos-list
            :videos="videos"
            :loading="isLoading"
            :updating="isUpdating"
            class="px-0"
        />

        <paging
            :total="total"
            :default-per-page="perPage"
            :loading="isUpdating"
            class="main-background px-0"
        />

        <video-action-dialogs @update="update" />
    </v-container>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { ListPaged } from '@/mixins';

import { Paging } from '@/components/Paging';

import VideosList from '@/components/VideosList/VideosList.vue';

import VideoActionDialogs from './VideoActionDialogs';

import type { AdminVideoAction, VideoPr } from '@/types/VideoPr';
import { ProvideReactive } from '@/utils/decorators';

@Component({
    components: {
        VideosList,
        Paging,
        VideoActionDialogs
    }
})
export default class Approval extends mixins(ListPaged) {
    endpoint = '/video_prs/approval';

    videos: VideoPr[] = [];

    onData(data: { length: number; videos: VideoPr[] }) {
        const { videos, length } = data;

        this.videos = videos;

        this.total = length;
    }

    @ProvideReactive()
    video: Partial<VideoPr> | null = null;

    @ProvideReactive()
    onAction(action: AdminVideoAction, video: VideoPr) {
        if (action === 'download') {
            this.download(video);
        } else {
            this.video = video;

            this.$store.dispatch('modal/open', `video-${action}`);
        }
    }

    async download(video: VideoPr) {
        this.$store.dispatch('loading/show');

        try {
            Object.assign(
                document.createElementNS('http://www.w3.org/1999/xhtml', 'a'),
                {
                    target: '_blank',
                    rel: 'noopener noreferrer',
                    href: `/video_prs/download/${video.id}`
                }
            ).click();
        } catch {
            this.$store.dispatch(
                'notification/error',
                'Unable to download Video. Please try again later or contact support.'
            );
        }

        this.$store.dispatch('loading/hide');
    }
}
</script>
